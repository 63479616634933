
















































































































































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LocaleMixin } from '@/locales/locale-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import { BaseStatuses } from '@/constants/status-constants';
import {
    formatDateForApi,
    formatDateWithTimezone,
    isoFormat,
    isValidDate,
    newDateForDatePicker
} from '@/date-time/date-time-utils';
import { ChangeStatus } from '@/families/change-status';
import { Child, ChildCreateDtoInterface } from '@/families/models/child';
import { ExtraStatusDataFields } from '@/crm-types/models/field-models';
import { getExtraStatusDataFields } from '@/crm-types/field-utils';
import { StatusesStore } from '@/families/store/statuses-store';
import { Family, PendingFamily } from '@/families/models/family';
import type { StatusChangeInterface } from '@/families/models/status';
import { Status } from '@/families/models/status';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { FieldsStore } from '@/crm-types/store/fields-store';
import { IntegrationExportChildInterface } from '@/integrations/models/integration';
import store from '@/store';
import StatusFieldEditor from '@/crm-types/components/StatusFieldEditor.vue';
import cloneDeep from 'lodash/cloneDeep';
import { StatusChangesStore } from '@/families/store/status-changes-store';
import isEqual from 'lodash/isEqual';

const changeStatusUtil = new ChangeStatus();
const featuresStore = getModule(FeaturesStore);
const fieldsStore = getModule(FieldsStore);
const statusStore = getModule(StatusesStore, store);
const statusChangesStore = getModule(StatusChangesStore);

@Component({
    components: { StatusFieldEditor }
})
export default class DuplicateStatusChangeSelect extends Mixins(LocaleMixin) {
    // Array of status IDs
    @Prop({ type: Array, default: null }) readonly statusIds!: Array<number>;
    // Whether the component has "Manage" capability
    @Prop({ type: Boolean }) readonly hasManage!: boolean;

    // The child object, if available
    @Prop({ default: null }) child!: Child | null | undefined;

    // Used to allow setting the status on a new child
    @Prop({ default: null }) childDto!: ChildCreateDtoInterface | null;

    // The family object, if available
    @Prop() family!: Family | PendingFamily | undefined;

   // Whether the select list should hide hints and errors
    @Prop({ default: false }) readonly hideDetails!: boolean | string;

    // Whether to show the label
    @Prop({ type: Boolean, default: true }) readonly showLabel!: boolean;

    // Whether a management system export is available
    @Prop({ type: Boolean, default: false }) readonly hasIntegration!: boolean;

    // Name of the management system
    @Prop({ type: String, default: '' }) readonly managementSystemName!: string;

    // Whether it's used without children context
    @Prop({ type: Boolean, default: false }) readonly withoutChildren!: boolean;

    // v-model stuff: the status change value
    @Prop() value!: StatusChangeInterface | null;

    private statusId: number | null = null;
    private needsDate = false;
    private needsExpectedStartDate = false;
    private requiresExpectedStartDate = false;
    private date: string = newDateForDatePicker();
    private expectedStartDate: string | null = null;
    private inputEvent = EventTypes.INPUT;
    private isCreated = false;
    private showExportDialog = false;
    private exportToManagementSystem = false;
    private originalStatusChangeObject: StatusChangeInterface | null = null;
    private originalDate: string | null = null;
    private originalExpectedStartDate: string | null = null;
    private statusChange: StatusChangeInterface | null = null;
    private statusFields = new ExtraStatusDataFields();
    private updatedEvent = EventTypes.UPDATED;
    private readOnly = false;

    // So we can compare stuff in the view code.
    private waitListStatusId = BaseStatuses.WAIT_LIST;
    private enrolledStatusId = BaseStatuses.ENROLLED;
    private tempLeaveStatusId = BaseStatuses.TEMP_LEAVE;
    private withdrawnStatusId = BaseStatuses.WITHDRAWN;
    private lostOppStatusId = BaseStatuses.LOST_OPP;
    private rejectedStatusId = BaseStatuses.REJECTED;

    get modelValue(): StatusChangeInterface | null {
        return this.value;
    }

    set modelValue(value: StatusChangeInterface | null) {
        this.$emit(EventTypes.INPUT, value);
    }

    // Computed getters.
    get isShowExtraFields(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isReasonRequired(): boolean {
        if (!this.statusId) {
            return false;
        }
        return changeStatusUtil.isReasonRequiredForStatus(this.statusId);
    }

    get isCommentRequired(): boolean {
        if (!this.statusId) {
            return false;
        }
        return changeStatusUtil.isCommentRequiredForStatus(this.statusId);
    }

    get items(): { text: string; value: number }[] {
        if (!this.statusIds || this.statusIds.length === 0) {
            return [];
        }

        const statuses: { text: string; value: number }[] = [];

        for (const statusId of this.statusIds) {
            const matchingStatus = changeStatusUtil.getStatusById(statusId);
            if (matchingStatus) {
                statuses.push({
                    text: matchingStatus.name,
                    value: matchingStatus.id
                });
            }
        }

        return statuses;
    }

    get showExpectedStartDate() {
        if (!(this.child || this.childDto)) {
            return false;
        }

        const noExpectedStartDateStatuses = [BaseStatuses.WITHDRAWN, BaseStatuses.TEMP_LEAVE];

        return !(this.statusId && (noExpectedStartDateStatuses.includes(this.statusId)));

    }

    get actualStartDateExists() {
        if (!this.statusId) {
            return false;
        }

        if (!this.statusFields.statusDateFields.actualStartDate) {
            return false;
        }

        return changeStatusUtil.hasActualStartDate(this.statusId);
    }

    get requiresActualStartDate() {
        if (!this.statusId) {
            return false;
        }

        return changeStatusUtil.requiresActualStartDate(this.statusId);
    }

    get label() {

        let label = 'Status for ';

        // Determine the name for the label
        let childName = null;
        if (this.childDto) {
            // A child being added
            childName = this.childDto.first_name ? `${this.childDto.first_name} ${this.childDto.last_name}` : 'New Child';
        } else if (this.child) {
            // A child already exists, changing their status
            childName = `${this.child.first_name} ${this.child.last_name}`;
        }

        label += childName || `${this.family?.primary_guardian.first_name} ${this.family?.primary_guardian.last_name}`;

        return label;
    }

    get dateLabel() {
        let dateName = '';
        switch (this.statusId) {
            case BaseStatuses.WAIT_LIST:
                dateName = 'Wait List Date';
                break;
            case BaseStatuses.ENROLLED:
                dateName = 'Actual Start Date';
                break;
            case BaseStatuses.TEMP_LEAVE:
                dateName = 'Temporary Leave Date';
                break;
            case BaseStatuses.WITHDRAWN:
                dateName = 'Withdrawn Date';
                break;
        }
        return `${dateName}`;
    }

    // Get extra data for status change, such as reasons.
    get hasExtraData(): boolean {
        if (this.statusId) {
            return changeStatusUtil.hasExtraData(this.statusId);
        }

        return false;
    }

    get isExportable(): boolean {
        if (this.hasManage) {
            // We will automatically send the family over as appropriate
            // So do not show the "export to Manage" checkbox
            return false;
        }
        if (this.date &&
            this.statusId &&
            this.hasIntegration &&
            ((this.child && !this.child.exported) || (this.childDto && !this.child)) &&
            [BaseStatuses.WAIT_LIST, BaseStatuses.REGISTERED, BaseStatuses.ENROLLED].includes(this.statusId)
        ) {
            this.exportToManagementSystem = true;
            return true;
        }

        this.exportToManagementSystem = false;
        return false;
    }

    get exportLabel() {
        return `Send To ${this.managementSystemName}`;
    }

    get childOnlyStatuses(): Array<Status> {
        return statusStore.statuses.filter(status => status.child_only);
    }

    @Watch('family', {
        immediate: true,
        deep: true
    })
    familyChanged() {
        this.configureStatuses();
    }

    @Watch('child', {
        immediate: true,
        deep: true
    })
    async childChanged() {
        if (this.child) {
            this.statusChange = statusChangesStore.changesForChildId(this.child.id);

            let date: string | null = null; // It's ok. We really check it later.

            if (this.child.status_details) {
                if (this.child.status_details.expected_start_date &&
                    isValidDate(this.child.status_details.expected_start_date)
                ) {
                    this.expectedStartDate = formatDateWithTimezone(
                        this.child.status_details.expected_start_date,
                        'UTC',
                        isoFormat
                    );
                    this.originalExpectedStartDate = this.expectedStartDate;
                }

                switch (this.statusId) {
                    case BaseStatuses.WAIT_LIST:
                        if (this.child.status_details.wait_list_date) {
                            date = this.child.status_details.wait_list_date;
                        }
                        break;
                    case BaseStatuses.ENROLLED:
                        date = this.child.status_details.enrolled_date
                            ? this.child.status_details.enrolled_date
                            : null;
                        break;
                    case BaseStatuses.TEMP_LEAVE:
                        if (this.child.status_details.temp_leave_date) {
                            date = this.child.status_details.temp_leave_date;
                        }
                        break;
                    case BaseStatuses.WITHDRAWN:
                        if (this.child.status_details.withdrawn_date) {
                            date = this.child.status_details.withdrawn_date;
                        }
                        break;
                }

            }

            if (date && isValidDate(date)) {
                this.date = formatDateWithTimezone(date, 'UTC', isoFormat);
                this.originalDate = this.date;
            }
        }
    }

    @Watch('modelValue', { immediate: true, deep: true })
    private updateStatuses() {
        if (!isEqual(this.statusChange, this.modelValue)) {
            this.statusChange = this.modelValue;
            if (this.modelValue?.status) {
                this.statusId = this.modelValue.status;
            }
        }
    }

    configureStatuses() {
        if (this.family) {
            this.statusId = this.withoutChildren
                ? this.family.status?.id ?? 1
                : this.child?.status?.id ?? this.childDto?.status ?? null; // ChildPostDto doesn't have status on Duplicates modal
        } else {
            this.statusId = 1;
        }

        if (!this.originalStatusChangeObject) {
            this.originalStatusChangeObject = cloneDeep(this.statusChange);
        }
    }

    // Watch this!
    @Watch('statusId', { immediate: true })
    changeStatus() {
        if (!this.statusId) {
            // No real change; do nothing.
            return;
        }

        this.setStatusChangeData();
        this.emitExportCheckbox();
        this.emitStatusChange();
    }

    @Watch('exportToManagementSystem')
    emitExportCheckbox() {
        this.$emit(EventTypes.CHILD_EXPORT_CHECKED, {
            child: this.child ? this.child : this.childDto,
            export: (this.exportToManagementSystem && this.isExportable)
        } as IntegrationExportChildInterface);
    }

    @Watch('statusChange.withdrawn_details.is_eligible_for_reenrollment', { deep: true, immediate: true })
    updateReadOnlyProp() {
        if (this.statusId === BaseStatuses.WITHDRAWN) {
            if (this.statusChange && this.statusChange.withdrawn_details && this.statusChange.withdrawn_details.is_eligible_for_reenrollment !== null) {
                this.readOnly = !this.statusChange.withdrawn_details.is_eligible_for_reenrollment;
            }
        }
    }

    // The lifecycle.
    async created() {
        await statusStore.init();
        await fieldsStore.init();

        this.configureStatuses();

        this.statusFields = getExtraStatusDataFields(fieldsStore.stored);
        // Wait a render cycle before marking as created so that watches will have already been performed.
        this.finishCreation();
    }

    // Methods
    private setStatusChangeData(): void {
        if (!this.family) {
            return;
        }
        if (this.child) {
            this.statusChange = changeStatusUtil.setStatusChangeDetails(
                this.statusId || 1,
                this.family,
                this.child
            );
        }
    }

    private async mouseDownEvent() {
        if (this.statusId === BaseStatuses.WITHDRAWN) {
            if (this.readOnly) {
                await this.$swal({
                    icon: 'warning',
                    html: '<p>This child has not been marked as <br> eligible for re-' + this.$t('enrollment') + '</p>',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: 'Close',
                    focusCancel: true,
                    reverseButtons: true,
                    customClass: {
                        cancelButton: 'swal2-primary-button-styling',
                        confirmButton: 'swal2-secondary-button-styling'
                    }
                });
            }
        }

    }

    private emitStatusChange() {
        if (!this.statusId) {
            this.$emit(EventTypes.DISABLE_SAVE);
            return;
        }

        if (!this.statusChange) {
            this.$emit(EventTypes.DISABLE_SAVE);
            return;
        }

        if (!this.originalStatusChangeObject) {
            return;
        }

        if (this.statusId !== BaseStatuses.WAIT_LIST) {
            delete this.statusChange.wait_list_details;
        }

        if (this.statusId !== BaseStatuses.WITHDRAWN) {
            delete this.statusChange.withdrawn_details;
        }
        this.statusChange.status = this.statusId;

        if (changeStatusUtil.requiresExpectedStartDate(this.statusId) && !this.statusChange.expected_start_date) {
            this.$emit(EventTypes.DISABLE_SAVE);
            return;
        }

        if (changeStatusUtil.requiresActualStartDate(this.statusId) && this.statusChange.actual_start_date) {
            if (!this.statusChange.date) {
                // if the status date isn't set but the actual start date is
                // just make them both the same, so it doesn't fail the next check
                this.statusChange.date = this.statusChange.actual_start_date;
            }
        }

        if (changeStatusUtil.requiresDate(this.statusId) && !this.statusChange.date) {
            this.$emit(EventTypes.DISABLE_SAVE);
            return;
        }

        if (this.originalStatusChangeObject && !changeStatusUtil.isChanged(this.originalStatusChangeObject, this.statusChange)) {
            // No changes should happen if they didn't make any changes
            // This is to avoid accidentally moving somebody back to their original status
            // after a workflow has triggered that changed status -- race condition
            this.$emit(EventTypes.DISABLE_SAVE);
            return;
        }

        if (!this.statusChange.date && !this.withoutChildren) {
            // We always need a date for the actual status change
            this.statusChange.date = formatDateForApi(new Date());
        }

        // Handle zeros for the value
        if (this.statusChange.wait_list_details?.fee !== null && this.statusChange.wait_list_details?.fee !== undefined) {
            // Ensure we're cast to the correct type for a number value that the API wants as a string
            this.statusChange.wait_list_details.fee = this.statusChange.wait_list_details.fee.toString();
        }

        statusChangesStore.storeChange(this.statusChange);
        this.$emit(EventTypes.UPDATED, this.statusChange);
    }

    // Perform actions after finishing creation.
    private finishCreation() {
        // Wait a render cycle before marking as created so that watches will have already been performed.
        this.$nextTick(() => {
            this.isCreated = true;
        });

        this.setStatusChangeData();
        // For tracking if the original state matches current state
        this.originalStatusChangeObject = cloneDeep(this.statusChange);
        this.$emit(EventTypes.DISABLE_SAVE);
    }

}
