







































































































































/**
 * This component is used to edit status-related data
 */
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { EventTypes } from '@/constants/event-type-constants';
import { ChildFields, FieldTypes } from '@/crm-types/models/field-models';
import { formatDateForApi } from '@/date-time/date-time-utils';
import { RequiredFieldsStore } from '@/crm-types/store/required-fields-store';
import {
    clearPropertyForStatusChange,
    getCrmListForField,
    getStatusChangePropertyNameForField,
    getStatusPropertyForFieldFromModel,
    setStatusChangePropertyForFieldFromModel
} from '@/crm-types/field-utils';
import type { Field } from '@/crm-types/models/field-models';
import type { StatusChangeInterface } from '@/families/models/status';
import MiscUtils from '@/utils/misc-utils';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';

const requiredFieldsStore = getModule(RequiredFieldsStore);
const typesStore = getModule(CrmTypesStore);

@Component({
    components: { CrmTypeSelectList }
})
export default class StatusFieldEditor extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) statusChange!: StatusChangeInterface;
    @Prop({ required: true }) field!: Field | null;
    @Prop({ default: false }) isDisabled!: boolean;
    @Prop({ default: false }) readonly hideDetails!: boolean | string;
    @Prop({ default: true }) show!: boolean;
    @Prop({ default: false }) readonly forceRequired!: boolean;
    @Prop({ default: false }) readonly isEmphasized!: boolean;
    @Prop({ default: false }) readonly forDuplicatesModal!: string;
    @Prop({ default: '' }) readonly labelOverride!: string;

    private date: string | null = null;
    private hasRemoved = false;
    private items: Array<CrmTypeOption> = [];

    // Getters and setters.
    get isCheckbox(): boolean {
        return this.field?.type.id === FieldTypes.CHECKBOX;
    }

    get isCurrency(): boolean {
        return this.field?.type.id === FieldTypes.CURRENCY;
    }

    get isDate(): boolean {
        return this.field?.type.id === FieldTypes.DATE;
    }

    get isStartDate(): boolean {
        return (this.field?.value === ChildFields.ACTUAL_START_DATE || this.field?.value === ChildFields.EXPECTED_START_DATE);
    }

    get isRequired(): boolean {
        return this.show && (this.forceRequired || (this.field?.is_system_required ?? false) || (this.field?.is_client_required ?? false));
    }

    get isSelectList(): boolean {
        return this.field?.type.id === FieldTypes.SELECT_LIST;
    }

    get isText(): boolean {
        return this.field?.type.id === FieldTypes.TEXT_FIELD;
    }

    get label(): string {
        if (!this.field) {
            return '';
        }
        if (this.labelOverride) {
            return this.labelOverride;
        }
        if (this.field.value === ChildFields.ELIGIBLE_FOR_REENROLLMENT) {
            return `Eligible for Re-${this.$t('enrollment')}`;
        }
        return this.field.value;
    }

    get list(): CrmTypeList | null {
        if (!this.field) {
            return null;
        }
        return getCrmListForField(this.field);
    }

    get modelProperty() {
        if (!this.field) {
            return null;
        }
        const propertyName = getStatusChangePropertyNameForField(this.field);
        if (!propertyName || !this.statusChange) {
            return null;
        }

        let value = getStatusPropertyForFieldFromModel(this.statusChange, this.field);

        if (this.isDate) {
            this.date = value !== null ? formatDateForApi(value) : '';
            return this.date;
        }

        if (this.isSelectList && this.items.length) {
            // Convert string to an id for select lists
            const values = this.items.filter(item => item.id === value);
            value = values.length ? values[0].id : null;
        }

        return value;
    }

    set modelProperty(value: string | number | boolean | null) {
        if (!this.field) {
            return;
        }

        const propertyName = getStatusChangePropertyNameForField(this.field);
        if (!propertyName || !this.statusChange) {
            return;
        }

        let newValue: any = value;

        if (this.isDate) {
            if (value) {
                // Handle when dates get cleared
                this.date = formatDateForApi(value as string);
            } else {
                this.date = '';
            }

            if (MiscUtils.empty(value)) {
                newValue = undefined;
            }
        }

        setStatusChangePropertyForFieldFromModel(this.statusChange, this.field, newValue);

        if (value === null || value.toString().length === 0 || value.toString() === '') {
            // Clear values that users want to clear
            // If it's required, save will get disabled by the requiredFieldsStore check in Family Hub
            clearPropertyForStatusChange(this.statusChange, this.field);
        }

        if (this.isRequired && !this.isDisabled && this.show) {
            const key = this.getStatusChangeKey(this.statusChange) + '-' + this.field.value;
            if (value !== null && value.toString().length > 0 && value.toString() !== '') {
                // Prevent updating the store many times for text values
                if (!this.hasRemoved) {
                    requiredFieldsStore.removeFromList({ familyId: this.statusChange.family_id, fieldName: key });
                    this.hasRemoved = true;
                    this.$emit(EventTypes.UPDATED);
                    return;
                }
            } else {
                requiredFieldsStore.addToList({ familyId: this.statusChange.family_id, fieldName: key });
                this.hasRemoved = false;
                this.$emit(EventTypes.UPDATED);
                return;
            }
        }

        this.modifyRequiredFieldsLists();

        this.$emit(EventTypes.UPDATED);
    }

    @Watch('field')
    async updateFields() {
        if (!this.field) {
            return;
        }
        this.modifyRequiredFieldsLists();
        if (this.isSelectList) {
            this.items = await this.getItems();
        }
    }

    @Watch('model')
    updateModel() {
        this.modifyRequiredFieldsLists();
    }

    @Watch('isRequired')
    updateIsRequired() {
        // if a field's required state changes, update required fields store
        this.modifyRequiredFieldsLists();
    }

    @Watch('show')
    updateIsShow() {
        // If a field is unhidden, update store of required fields
        this.modifyRequiredFieldsLists();
    }

    clearDate() {
        this.modelProperty = null;
    }

    modifyRequiredFieldsLists() {
        if (!this.field || !this.statusChange) {
            return;
        }

        if (!this.show || !this.isRequired) {
            requiredFieldsStore.removeFromList({
                familyId: this.statusChange.family_id,
                fieldName: this.getStatusChangeKey(this.statusChange) + '-' + this.field.value
            });
        } else if (this.isRequired && !this.modelProperty && !this.isDisabled) {
            requiredFieldsStore.addToList({
                familyId: this.statusChange.family_id,
                fieldName: this.getStatusChangeKey(this.statusChange) + '-' + this.field.value
            });
        }
    }

    // Lifecycle stuff.
    async mounted() {
        if (this.isSelectList) {
            this.items = await this.getItems();
        }
        this.modifyRequiredFieldsLists();
    }

    // Other methods.
    async getItems() {
        if (!this.list) {
            return [];
        }

        await typesStore.initAllList(this.list);
        return typesStore.allListOptions(this.list);
    }

    private getStatusChangeKey(statusChange: StatusChangeInterface): string {
        return statusChange.child_id ? `c-${statusChange.child_id}` : `f-${statusChange.family_id}`;
    }
}
